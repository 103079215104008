































































import VueBase from '@/VueBase'
import { Upload } from 'element-ui'
import { Component } from 'vue-property-decorator'

interface myUpLoad extends Upload {
  uploadFiles: Array<{
    raw: File
  }>
}

@Component({ name: 'UpgradeOnline' })
export default class UpgradeOnline extends VueBase {
  private token = ''
  private upgrade_url = 'asd'
  private editUrl = false
  private fileList = []

  created() {
    this.userToken()
  }

  private async userToken() {
    const {
      status,
      msg,
      token,
      upgrade_url,
    } = await this.services.user.userToken()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.token = token
    this.upgrade_url = upgrade_url
  }

  private async upgradeOnline() {
    const params = {
      url: this.upgrade_url,
      token: this.token,
    }
    const { status, msg } = await this.services.setting.upgradeOnline(
      `Token ${this.token}`,
      params
    )
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: msg,
      showClose: true,
    })
  }

  private async uploadOneFile() {
    console.log(this.$refs.upload)
    const formData = new FormData()
    formData.append('file', (this.$refs.upload as myUpLoad).uploadFiles[0].raw)
    const { status, msg } = await this.services.setting.upgradeOffline(formData)
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
  }
}
